<template>
    <!-- Error 500-->
    <div class="misc-wrapper">
        <b-link class="brand-logo">
            <vuexy-logo />
            <h2 class="brand-text text-primary ml-1">{{ appName }}</h2>
        </b-link>

        <div class="misc-inner p-2 p-sm-3">
            <div class="w-100 text-center">
                <h2 class="mb-1">Something went wrong 🚀</h2>
                <p class="mb-3">We are sorry, please notify the system administrator!</p>

                <!-- form -->
                <b-form inline class="row justify-content-center m-0 mb-2" @submit.prevent>
                    <b-form-input
                        id="notify-email"
                        class="col-12 col-md-5 mb-1 mr-md-2"
                        type="text"
                        placeholder="john@example.com"
                    />

                    <b-button variant="primary" class="mb-1 btn-sm-block" type="submit"> Notify </b-button>
                </b-form>

                <b-img fluid :src="imgUrl" alt="Coming soon page" />
            </div>
        </div>
    </div>
    <!-- / Error 500-->
</template>

<script>
/* eslint-disable global-require */
import { BLink, BForm, BFormInput, BButton, BImg } from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import store from '@/store/index'
import { $themeConfig } from '@themeConfig'

export default {
    components: {
        VuexyLogo,
        BLink,
        BForm,
        BButton,
        BFormInput,
        BImg,
    },
    data() {
        return {
            appName: $themeConfig.app.appName,
            message: 'We are sorry, please notify the system administrator!',
        }
    },
    computed: {
        imgUrl() {
            let image = require('@/assets/images/pages/coming-soon.svg')

            if (store.state.appConfig.layout.skin === 'dark') {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                image = require('@/assets/images/pages/coming-soon-dark.svg')

                return image
            }

            return image
        },
    },
    created() {
        let message = this.$route.query.message

        if (message) {
            this.message = message
        }
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
